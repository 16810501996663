interface Props {
  locale: string;
  link: string;
}

export const localizeLink = ({
  locale,
  link,
}: Props): string => {
  const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE;

  if (!link) {
    return '';
  }

  let path = `/${ link }`;

  if (locale !== defaultLocale && !link.startsWith(`/${ locale }/`)) {
    path = `/${ locale }/${ link }`;
  }

  return path.replace(/\/+/g, '/');
};

export const isExternal = (url: string): boolean => {
  // certainly external
  if (/^((https?:)|(s?ftps?:)|(mailto:)|(tel:)|(skype:))/.test(url)) {
    return true;
  }

  // a file
  if (/\.[a-zA-Z0-9]+$/.test(url)) {
    return true;
  }

  // starting with slash+char
  if (/^\/(?!\/)/.test(url)) {
    return false;
  }

  // internal by default
  return false;
};

// eslint-disable-next-line max-len
export const isNewTabLink = (url: string): boolean => /^((https?:)|(s?ftps?:))/.test(url);
