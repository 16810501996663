import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Locales } from 'src/application/enums/locales';
import {
  Header as CmsContent,
  useHeaderData,
} from 'src/infrastructure/hooks/use-header-data';
import {
  onTrackShipmentClick,
  setHeaderSubmenu,
  setMobileSubmenu,
  menuToggle,
  headerMounted,
} from 'src/application/core/header/actions';
import { useTranslation } from 'src/views/misc/localization';
import { PageContext } from 'src/views/templates/context';
import PureHeader from '@shipae/components-sandbox/component/header';
import {
  IHeaderContent,
  ILocale,
} from '@shipae/components-sandbox/component/header/types';
import { getHeader } from 'src/application/core/header/selectors';
import { ROUTES } from 'src/views/constants/ROUTES';
import SmartLink from '../smart-link';
import { useDifferentLogoForKSA } from './use-different-logo-hook';
import { useGetInTouchId } from './use-get-in-touch-id';

const prepareContent = (
  cmsContent: CmsContent,
  locale: string,
): IHeaderContent => ({
  homeUrl: ROUTES[locale].HOME,
  logo: {
    url: cmsContent.logo.localFile.publicURL,
    alternativeText: cmsContent.logo.alternativeText || '',
  },
  navigationLinks: cmsContent.navigationLinks.map((navLink) => ({
    ...navLink,
    submenuRows: navLink.submenuRows.map((row) => ({
      ...row,
      submenuItems: row.submenuItems.map((item) => ({
        ...item,
        icon: !item.icon ? undefined : {
          url: item.icon.localFile.publicURL,
          alternativeText: item.icon.alternativeText || '',
        },
      })),
    })),
  })),
  secondaryNavigationLinks: cmsContent.secondaryNavigationLinks.map(
    (secLink) => ({
      ...secLink,
      icon: {
        url: secLink.icon.localFile.publicURL,
        alternativeText: secLink.icon.alternativeText || '',
      },
      submenuItems: secLink.submenuItems.map((item) => ({
        ...item,
        icon: {
          url: item.icon.localFile.publicURL,
          alternativeText: item.icon.alternativeText || '',
        },
      })),
    }),
  ),
});

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const header = useHeaderData();
  const {
    headerSubmenu,
    menuOpen,
    mobileSubmenuId,
  } = useSelector(getHeader);
  useEffect(() => {
    dispatch(headerMounted);
  }, []);

  const { translationLinks } = useContext(PageContext);
  const { t, locale } = useTranslation();
  const localizedContent = header[locale as Locales];

  if (!localizedContent) return null;

  const content = useMemo(
    () => prepareContent(localizedContent, locale),
    [localizedContent, locale],
  );

  /**
   * We need an id of Get In Touch secondary link
   * to be able to open its submenu from other components
   * For more, check who is using the getInTouchId prop.
   */
  useGetInTouchId(content.secondaryNavigationLinks);
  useDifferentLogoForKSA(); // Only works in Egypt

  return (
    <div data-ga="Header">
      <PureHeader
        content={content}
        locale={locale as ILocale}
        linkComponent={SmartLink}
        t={t}
        translationLinks={translationLinks}
        widgetActions={{
          onTrackShipmentClick: (currentLocale: string) => dispatch(
            onTrackShipmentClick(currentLocale),
          ),
        }}
        headerSubmenu={headerSubmenu}
        setHeaderSubmenu={(id: string | null) => dispatch(setHeaderSubmenu(id))}
        isMenuOpen={menuOpen}
        setMenuOpen={(isOpen: boolean) => dispatch(menuToggle(isOpen))}
        mobileSubmenuId={mobileSubmenuId}
        setMobileSubmenuId={
          (id: string | null) => dispatch(setMobileSubmenu(id))
        }
      />
    </div>
  );
};

export default Header;
