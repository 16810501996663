import path from 'path';
import dotenv, { DotenvConfigOutput } from 'dotenv';

/**
 * Load env file for the current environment
 * Gatsby env resolution logic:
 * a) gatsby develop => process.env.NODE_ENV = 'development'
 * b) gatsby build =>  process.env.NODE_ENV = 'production'
 * GATSBY_ACTIVE_ENV allows us to control env from cmd or AWS env vars
 * More: https://www.gatsbyjs.org/docs/environment-variables/#server-side-nodejs
 */
export const initializeEnvironment = (): DotenvConfigOutput => {
  const configEnv = process.env.GATSBY_ACTIVE_ENV
  || process.env.NODE_ENV
  || 'development';

  return dotenv.config({
    path: path.resolve(`${ __dirname }/../../../../.env.${ configEnv }`),
  });
};

export const isProduction = (
):boolean => process.env.GATSBY_ACTIVE_ENV === 'production';

initializeEnvironment();
