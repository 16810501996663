const config = {
  siteTitle: 'Shipa',
  siteDescription: 'Shipa offers smart online solutions to save you time and money. Through e-commerce logistics, deliveries, and online freight forwarding, we connect your business to the world.', // eslint-disable-line max-len
  siteColors: {
    background: '#fff',
    theme: '#000',
  },
  twitterUsername: 'ShipaWorld',
  siteIcons: {
    /* This path is relative to the root of the site. */
    favicon: 'src/views/images/favicon.png',
    image: 'src/views/images/favicon.png',
  },
  googleSearchConsoleTag: {
    name: 'google-site-verification',
    content: '_5t1unb5_-5NrCiz9a0f_Az7oLgmLNKr_6M7iFAlI4o',
  },
  facebookVerificationTag: {
    name: 'facebook-domain-verification',
    content: 'na7v0txkgmmh0vfr2cygufgesrwl4l',
  },
  intercomId: 'wymbkmiz',
  gtmId: 'GTM-TJFN8VK',
  defaultRecaptchaSiteKey: '6LdRUuQZAAAAAJNR8y_0twz2tIAzNw82hCRnA_2Y',
  testRecaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  eagerSectionsLoadCount: 2,
};

export default config;
