import { media } from 'src/views/theme';
import { createGlobalStyle } from 'styled-components';

export const ProjectStyles = createGlobalStyle`
  :root {
    --container-width: 88%;

    ${ media.max(1000) } {
        --container-width: 95%;
    }

    ${ media.max(375) } {
      --container-width: 90%;
    }
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    body.using-mouse &:active,
    body.using-mouse &:focus {
      outline: none;
    }
    ${ ''/* eslint-disable-next-line max-len */ }
    font-family: 'Futura PT', serif, sans-serif, Arial, Helvetica, "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

  }

  html[dir='rtl'] * {
    ${ ''/* eslint-disable-next-line max-len */ }
    font-family: 'Futura PT', Arial,'Times New Roman', serif, Arial, Helvetica;
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }
`;
