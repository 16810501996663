export type Route = {
  [key: string]: string,
}

export type Routes = {
  [key: string]: Route,
}

export const ROUTES: Routes = {
  en: {
    HOME: '/',
    SITEMAP: '/sitemap',
  },
  ar: {
    HOME: '/ar/',
    SITEMAP: '/ar/sitemap',
  },
};
