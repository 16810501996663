export const validateRequired = (
  value: InputValue,
): 'fieldRequired' | '' => {
  if (Array.isArray(value)) {
    return !value.length ? 'fieldRequired' : '';
  }
  if (
    typeof value === 'object'
    && typeof value?.name === 'string'
    && typeof value?.size === 'number'
  ) {
    return '';
  }
  if (typeof value === 'object' && value !== null) {
    const isEmpty = Object.values(value).every((val) => val === false);

    return isEmpty ? 'fieldRequired' : '';
  }
  return !value ? 'fieldRequired' : '';
};

export const validateEmail = (
  email?: InputValue,
): string => {
  const error = 'invalidEmail';

  if (!email) return error;

  if (typeof email !== 'string') return '';

  return /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g.test(email) ? '' : error;
};

const hasExtension = (
  fileName: string,
  validExtensions: string[],
) => (
  new RegExp(`(${ validExtensions.join('|').replace(/\./g, '\\.') })$`)
).test(fileName);

export const validatePdfOrWord = (
  value?: InputValue,
): string => {
  if (typeof value === 'string' || Array.isArray(value)) return '';
  if (!value?.name) return '';

  if (typeof value.name === 'string') {
    if (hasExtension(value?.name, ['.pdf', '.doc', '.docx'])) return '';
  }

  return 'onlyPdfOrWordFormatAllowed';
};

export const validate5MbMaxFileSize = (
  value?: InputValue,
): string => {
  if (typeof value === 'string' || Array.isArray(value)) return '';
  if (!value?.name) return '';

  if (typeof value.name === 'string') {
    if (value.size < 5000001) return '';
  }

  return 'maxSize5Mb';
};

type BooleanObject = {[key: string]: boolean};

type InputValue =
  null
  | undefined
  | string
  | File
  | BooleanObject
  | string[];

export type Validator =
  typeof validateRequired
  | typeof validateEmail
  | typeof validatePdfOrWord;
