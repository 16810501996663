import { graphql, useStaticQuery } from 'gatsby';
import { Svg } from '../gatsby/types/svg-type';

export type HeaderData = {
  en: Header;
  ar: Header;
}

export type Header = {
  logo: Svg;
  navigationLinks: NavLink[];
  secondaryNavigationLinks: SecondaryNavLink[];
}

export type NavLink = {
  id: string;
  label: string;
  link: string;
  submenuRows: SubmenuRow[]
}

export type SubmenuRow = {
  id: string;
  title: string;
  linkDisplayType: LinkDisplayType;
  viewAllLink?: Link;
  submenuItems: {
    id: string;
    title: string;
    description?: string;
    link: string;
    icon?: Svg;
  }[]
}

export type LinkDisplayType = 'horizontalWithIconsDescription'
  | 'verticalTitleOnly';

export type Link = {
  url?: string;
  label?: string;
};

export type SecondaryNavLink = {
  id: string;
  label: string;
  handlerId: string;
  icon: Svg;
  submenuButton: {
    id: string;
    label: string;
    link: string;
  }
  submenuItems: {
    id: string;
    label: string;
    link: string;
    icon: Svg;
  }[]
};

export const useHeaderData = (
): HeaderData => useStaticQuery<HeaderData>(graphql`
    query {
      ar: strapiHeader(language: {eq: "ar"}) {
        logo {
          alternativeText
          localFile {
            publicURL
          }
        }
        language
        navigationLinks {
          id
          label
          link
          submenuRows {
            id
            title
            linkDisplayType
            viewAllLink {
              id
              url
              label
            }
            submenuItems {
              id
              title
              description
              link
              icon {
                alternativeText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        secondaryNavigationLinks {
          id
          label
          handlerId
          submenuButton {
            id
            label
            link
          }
          submenuItems {
            id
            label
            link
            icon {
              alternativeText
              localFile {
                publicURL
              }
            }
          }
          icon {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
      en: strapiHeader(language: {eq: "en"}) {
        logo {
          alternativeText
          localFile {
            publicURL
          }
        }
        language
        navigationLinks {
          id
          label
          link
          submenuRows {
            id
            title
            linkDisplayType
            viewAllLink {
              id
              url
              label
            }
            submenuItems {
              id
              title
              description
              link
              icon {
                alternativeText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        secondaryNavigationLinks {
          id
          label
          handlerId
          submenuButton {
            id
            label
            link
          }
          submenuItems {
            id
            label
            link
            icon {
              alternativeText
              localFile {
                publicURL
              }
            }
          }
          icon {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);
