import React from 'react';
import styled from 'styled-components';

export type SmartLinkThemeType = {
  Main: React.ElementType,
}

interface ILink {
  external?: boolean;
}

const SmartLinkTheme: SmartLinkThemeType = {
  Main: styled.a<ILink>``,
};

export default SmartLinkTheme;
