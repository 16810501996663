import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getHeader } from '../../../application/core/header/selectors';

const englishLabel = 'The rules of protecting the beneficiary rights';
const arabicLabel = 'قواعد حماية المستفيد';
// eslint-disable-next-line max-len
const documentUrl = 'https://prod-shipa-cms-media.s3.eu-west-1.amazonaws.com/users_rights_postal_733d332cd6.pdf';

export const useLegalLinkinKSA = ():void => {
  const { geolocation } = useSelector(getHeader);
  useEffect(() => {
    if (!geolocation || !geolocation.countryCode) return;

    const isEgypt = geolocation.countryCode.toLowerCase() === 'sa';
    if (!isEgypt) return;

    const { lang } = document.documentElement;

    if (!lang) return;

    const columns = document.querySelectorAll(
      '[data-gtm="footer-column-item"]',
    );

    const legalColumn = Array.from(columns).filter(
      (c) => (c?.firstChild as HTMLElement)?.innerText === 'Legal'
          || (c?.firstChild as HTMLElement)?.innerText === 'الشؤون القانونية',
    )[0];

    const link = document.createElement('a');
    link.setAttribute(
      'href',
      documentUrl,
    );
    link.setAttribute('target', '_blank');
    link.className = (
          legalColumn?.firstChild?.nextSibling as HTMLElement
        )?.className || '';
    link.innerHTML = lang === 'en' ? englishLabel : arabicLabel;
    legalColumn.append(link);
  }, [geolocation]);
};
