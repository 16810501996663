import react from 'react'; // eslint-disable-line
import { graphql, useStaticQuery } from 'gatsby';
import { Svg } from '../gatsby/types/svg-type';

export type FooterData = {
  en: Footer;
  ar: Footer;
}

export type Footer = {
  logo: Svg;
  copyright: string;
  topPanel?: TopPanel;
  columns: Column[];
  socialMediaLinks: FooterMediaLink[];
  bottomIcons: FooterMediaLink[];
}

export type TopPanel = {
  id: string;
  leftAction: TopPanelGroup;
  rightAction: TopPanelGroup;
}

type TopPanelGroup = {
  id: string;
  subHeading?: string;
  label: string;
  link?: string;
  fallbackHandlerId?: 'onOpenChatClick';
  icon: Svg;
}

export type Column = {
  id: string;
  columnItems: ColumnItem[];
}

export type ColumnItem = {
  id: string;
  title?: string;
  links: ColumnLink[];
}

type ColumnLink = {
  id: string;
  label: string;
  link: string;
  subLinks: ColumnSubLink[];
}

type ColumnSubLink = {
  id: string;
  label: string;
  link: string;
}

type FooterMediaLink = {
  id: string;
  link: string;
  icon: Svg;
}

export const useFooterData = (
): FooterData => useStaticQuery<FooterData>(graphql`
    query {
      en: strapiFooter(language: {eq: "en"}) {
        logo {
          alternativeText
          localFile {
            publicURL
          }
        }
        bottomIcons {
          id
          link
          icon {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        copyright
        columns {
          id
          columnItems {
            id
            title
            links {
              id
              label
              link
              subLinks {
                label
                link
              }
            }
          }
        }
        socialMediaLinks {
          id
          link
          icon {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        topPanel {
          id
          leftAction {
            id
            label
            link
            subHeading
            fallbackHandlerId
            icon {
              alternativeText
              localFile {
                publicURL
              }
            }
          }
          rightAction {
            id
            label
            link
            subHeading
            fallbackHandlerId
            icon {
              alternativeText
              localFile {
                publicURL
              }
            }
          }
        }
      }
      ar: strapiFooter(language: {eq: "ar"}) {
        logo {
          alternativeText
          localFile {
            publicURL
          }
        }
        bottomIcons {
          id
          link
          icon {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        copyright
        columns {
          id
          columnItems {
            id
            title
            links {
              id
              label
              link
              subLinks {
                label
                link
              }
            }
          }
        }
        socialMediaLinks {
          id
          link
          icon {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        topPanel {
          id
          leftAction {
            id
            label
            link
            subHeading
            fallbackHandlerId
            icon {
              alternativeText
              localFile {
                publicURL
              }
            }
          }
          rightAction {
            id
            label
            link
            subHeading
            fallbackHandlerId
            icon {
              alternativeText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
