import { useEffect } from 'react';
import { WindowLocation } from '@reach/router';

/**
 * Listens and registers into dataLayer all
 * clicks on Anchor links and elements with [data-ga-trigger]
 * @output Category - page section where the click occurred
 * Requirements: page section must have:
 * [data-ga='SectionName-${strapi-section-id}']
 * @output Label - identifier of clicked element
 * Requirements: must be either <a>, [data-ga-trigger] or their child
 * if <a> - registers href, if [data-ga-trigger] - inner text
 * @output CurrentUrl - identifier of current page
 */
const useAnalyticsListener = (
  location?: WindowLocation,
): void => {
  useEffect(() => {
    const checkIfLink = (el: HTMLElement): boolean => {
      const isLinkItself = el?.tagName.toLowerCase() === 'a';
      const isChildOfLink = el.closest('a') != null;

      return isLinkItself || isChildOfLink;
    };

    const checkIfGaTrigger = (el: HTMLElement): boolean => {
      const isTriggerItself = el.getAttribute('data-ga-trigger') != null;
      const isChildOfTrigger = el.closest('[data-ga-trigger]') != null;

      return isTriggerItself || isChildOfTrigger;
    };

    const listenToClicks = (e: MouseEvent) => {
      const currentUrl = location?.pathname || 'unavailable';
      const element = e.target as HTMLElement;

      const isLink = checkIfLink(element);
      const isGaTrigger = checkIfGaTrigger(element);

      const isTrackable = isLink || isGaTrigger;

      if (isTrackable) {
        const section = element?.closest('[data-ga]')?.getAttribute('data-ga')
          || 'unknown';

        const url = element.getAttribute('href')
          || (element.closest('a')
          && element?.closest('a')?.getAttribute('href'));

        const hasTextContent = element.textContent;

        let siblingTextContent;
        if (!hasTextContent) {
          const parent = element.closest('a')
            || element.closest('[data-ga-trigger]');

          if (parent) {
            siblingTextContent = parent.textContent;
          }
        }

        const ariaLabel = element.getAttribute('aria-label');
        const altText = element.getAttribute('alt');
        const imageText = ariaLabel
          ? `Img: ${ ariaLabel }`
          : `Img: ${ altText }`;

        const gaTextOverride = element.getAttribute('data-ga-text')
          || element.closest('[data-ga-text]')?.getAttribute('data-ga-text');

        const text = gaTextOverride
          || element.textContent
          || siblingTextContent
          || imageText
          || 'unknown';

        const eventBase = {
          Section: section,
          Text: text,
          event: 'unknown',
          CurrentURL: currentUrl,
        };
        const linkEvent = {
          event: 'linkClickEvent',
          LinkURL: url,
        };
        const tabEvent = {
          event: 'tabClickEvent',
        };

        const event = { ...eventBase, ...(isLink ? linkEvent : tabEvent) };

        if (window.dataLayer) {
          window.dataLayer.push(event);
        } else {
          // eslint-disable-next-line no-console
          console.error('dataLayer not defined:', window.dataLayer);
        }

        // const shouldLog = process.env.GATSBY_ACTIVE_ENV === 'local';
        // eslint-disable-next-line
        // shouldLog && console.info('dataLayer event', event);
      }
    };

    document.addEventListener('click', listenToClicks);
    return () => {
      document.removeEventListener('click', listenToClicks);
    };
  }, [location]);
};

export default useAnalyticsListener;
