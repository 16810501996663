import React, { FC, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { PageContext } from 'src/views/templates/context';
import { StyleSheetManager } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';

const RTLProvider: FC = ({ children }) => {
  const { locale } = useContext(PageContext);
  return (
    <StyleSheetManager stylisPlugins={
        locale === 'ar' ? [(rtlPlugin as any)] : [] /* eslint-disable-line */
        }
    >
      <>
        <Helmet>
          <html dir={locale === 'ar' ? 'rtl' : 'ltr'} lang={locale} />
        </Helmet>
        {children}
      </>
    </StyleSheetManager>
  );
};

export default RTLProvider;
