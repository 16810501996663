import React, { createContext, FC, useContext } from 'react';
import { PageContext } from 'src/views/templates/context';

import { IContextProps, Messages } from './types';
import {
  bindTranslation,
} from './utils';

const I18nContext = createContext({} as IContextProps);

const I18nProvider: FC = ({ children }) => {
  const {
    locale,
    labelsAndMessages,
  } = useContext(PageContext);

  const messages: Messages = {
    en: {},
    ar: {},
  };

  try {
    if (labelsAndMessages.en) {
      messages.en = JSON.parse(labelsAndMessages.en);
    }
  } catch (err) {
    messages.en = {};
  }

  try {
    if (labelsAndMessages.ar) {
      messages.ar = JSON.parse(labelsAndMessages.ar);
    }
  } catch (err) {
    messages.ar = {};
  }

  const { t } = bindTranslation({
    locale,
    messages,
  });

  const localeProps = { locale, t };

  return (
    <I18nContext.Provider value={localeProps}>{children}</I18nContext.Provider>
  );
};

const useTranslation = (): IContextProps => useContext(I18nContext);

export { I18nProvider, useTranslation };
