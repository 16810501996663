import React, { createContext, FC } from 'react';
import {
  LabelsAndMessages,
} from 'src/infrastructure/gatsby/types/label-and-messages';
import { PageSettings } from 'src/infrastructure/gatsby/types/page-settings';
import {
  AdditionalPageSettings,
} from 'src/infrastructure/gatsby/types/additional-page-settings';
import {
  TranslationLink,
} from 'src/infrastructure/gatsby/types/translation-link';

interface IProps {
  locale: string;
  translationLinks: TranslationLink[];
  pageSettings: PageSettings;
  additionalPageSettings?: AdditionalPageSettings;
  title: string;
  labelsAndMessages: Partial<LabelsAndMessages>;
}

export type IPageContext = IProps

const PageContextProvider: FC<IProps> = ({
  children,
  locale,
  translationLinks,
  pageSettings,
  additionalPageSettings,
  title,
  labelsAndMessages,
}) => (
  <PageContext.Provider
    value={{
      locale,
      translationLinks,
      pageSettings,
      additionalPageSettings,
      title,
      labelsAndMessages,
    }}
  >
    {children}
  </PageContext.Provider>
);

export const PageContext = createContext<IPageContext>({} as IPageContext);
export default PageContextProvider;
