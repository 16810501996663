import get from 'lodash.get';

import { IContextProps, Messages } from './types';

const bindTranslation = ({
  locale,
  messages,
}: { locale: string; messages: Messages }): { t: IContextProps['t'] } => {
  const t = (key: string): string => {
    const path = get(
      messages,
      [locale, key],
      get(messages.en, key, key),
    );

    return path;
  };

  return { t };
};

export { bindTranslation };
