import { useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { getHeader } from 'src/application/core/header/selectors';

type CustomCssStyleDeclaration = CSSStyleDeclaration & {
  '-webkit-mask': string;
}

type QueryResult = {
  englishImage?: {
    publicURL?: string;
  }
  arabicImage?: {
    publicURL?: string;
  }
}

export const useDifferentLogoForKSA = ():void => {
  const imgData = useStaticQuery<QueryResult>(graphql`
    query {
      englishImage: file(
        relativePath: {eq: "shipa-by-agility-en.svg"}
      ) {
        publicURL
      }
      arabicImage: file(
        relativePath: {eq: "shipa-by-agility-ar.svg"}
      ) {
        publicURL
      }
    }
  `);

  const { geolocation } = useSelector(getHeader);

  useEffect(() => {
    if (!geolocation || !geolocation?.countryCode) return;

    const isEgypt = geolocation.countryCode.toLowerCase() === 'sa';
    if (!isEgypt) return;

    const { lang } = document.documentElement;

    if (!lang) return;

    const imageUrl = lang === 'ar'
      ? imgData.arabicImage?.publicURL
      : imgData.englishImage?.publicURL;

    if (!imageUrl) return;

    const logoDesktop = document.querySelector(
      '[data-gtm=header-logo-desktop]',
    );

    if (logoDesktop) {
      logoDesktop.setAttribute(
        'src',
        imageUrl,
      );
    }

    const logoMobile = document.querySelector<HTMLElement>(
      '[data-gtm=header-logo-mobile]',
    );

    if (logoMobile) {
      (
            logoMobile.style as CustomCssStyleDeclaration
      )['-webkit-mask'] = `url(${ imageUrl })`;
    }
  }, [geolocation]);
};
