import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ISecondaryNavLink,
} from '@shipae/components-sandbox/component/header/types';
import {
  setGetInTouchId,
} from 'src/application/core/header/actions';

export const useGetInTouchId = (
  secondaryLinks: ISecondaryNavLink[],
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * Assumption: if a submenu has a button with link
     * /help-center/, then it's a get in touch submenu
     * Works both in En and Ar
     */
    const getInTouchItem = secondaryLinks.find(
      (link) => link?.submenuButton?.link?.includes('/help-center/'),
    );

    if (!getInTouchItem) return;

    dispatch(setGetInTouchId(getInTouchItem.id));
  }, [secondaryLinks]);
};
