import { graphql, PageProps, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

import SeoPure from '@shipae/components-sandbox/component/seo';

import config from 'src/config';
import { isProduction } from 'src/infrastructure/gatsby/environment';
import {
  TranslationLink,
} from 'src/infrastructure/gatsby/types/translation-link';
import { useTranslation } from 'src/views/misc/localization';

interface IProps {
  metaTitle?: string;
  metaDescription?: string;
  metaIndex?: string;
  metaFollow?: string;
  canonicalUrl?: string;
  location: PageProps['location'];
  pageType: 'article' | 'website';
  meta?: HTMLMetaElement[];
  translationLinks: TranslationLink[],
  socialPreviewImage?: string;
}

type QueryResult = {
  site: {
    siteMetadata: {
      siteUrl?: string;
    }
  }
  image?: {
    publicURL?: string;
  }
}

const {
  siteTitle,
  siteDescription,
  twitterUsername,
  googleSearchConsoleTag,
  facebookVerificationTag,
} = config;

const SEO: FC<IProps> = ({
  metaTitle,
  metaDescription,
  metaIndex = 'noindex',
  metaFollow = 'nofollow',
  canonicalUrl = null,
  location,
  pageType = 'website',
  meta = [],
  translationLinks = [],
  socialPreviewImage = '',
}) => {
  const data = useStaticQuery<QueryResult>(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      image: file(
        relativePath: {eq: "shipa-preview.png"}
      ) {
        publicURL
      }
    }
  `);
  const { siteUrl } = data.site.siteMetadata;
  const imageUrl = socialPreviewImage || data.image?.publicURL || '';
  const currentRoute = siteUrl + location.pathname;
  const { locale } = useTranslation();

  const extraMetaTags = isProduction() ? [
    {
      name: googleSearchConsoleTag.name,
      content: googleSearchConsoleTag.content,
    },
    {
      name: facebookVerificationTag.name,
      content: facebookVerificationTag.content,
    },
  ] : [];

  return (
    <SeoPure
      language={locale as 'en' | 'ar'}
      pageTitle={metaTitle || siteTitle}
      pageDescription={metaDescription || siteDescription}
      pageUrl={currentRoute}
      pageType={pageType}
      siteTitle={siteTitle}
      siteUrl={siteUrl || 'https://shipa.com'}
      canonicalUrl={canonicalUrl || currentRoute}
      socialImageUrl={siteUrl + imageUrl}
      twitterUsername={`@${ twitterUsername }`}
      translationUrls={translationLinks}
      robotsContent={isProduction()
        ? `${ metaIndex }, ${ metaFollow }, noodp, noydir`
        : 'noindex, nofollow, noodp, noydir'}
      meta={[...meta, ...(extraMetaTags as HTMLMetaElement[])]}
    />
  );
};

export default SEO;
