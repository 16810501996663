import React from 'react';
import { Image } from 'src/infrastructure/gatsby/types/image';
import { Section, Logo, LogoSvg } from './styles';

type Props = {
  image: Image;
};

const TopLogo: React.FC<Props> = ({ image }) => (
  <Section>
    {image?.localFile?.childImageSharp?.fluid && (
      <Logo
        fluid={image.localFile?.childImageSharp.fluid}
        objectFit="contain"
        objectPosition="0% 50%"
      />
    )}
    {!image?.localFile?.childImageSharp?.fluid
    && image?.localFile?.publicURL && (
      <LogoSvg
        src={image.localFile?.publicURL}
        alt=""
        loading="lazy"
      />
    )}
  </Section>
);

export default TopLogo;
