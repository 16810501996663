import styled from 'styled-components';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { GatsbyImageProps } from 'gatsby-image';

export const Section = styled.section`
  padding: 0 calc((100% - var(--container-width)) / 2);
`;

export const Logo = styled(GatsbyImage)<GatsbyImageProps>`
  height: 6.3rem;
`;

export const LogoSvg = styled.img`
  height: 6.3rem;
`;
