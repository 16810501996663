import { LinkType } from '@shipae/components-sandbox/component/breadcrumbs';
import { Locales } from 'src/application/enums/locales';
import { ROUTES } from '../constants/ROUTES';

interface ICalculateBreadcrumbs {
  parentTitle: string | null;
  parentPath: string | null;
  title: string;
}

export const calculateBreadcrumbs = ({
  parentTitle,
  parentPath,
  title,
}: ICalculateBreadcrumbs): LinkType[] => {
  const path = parentPath && `/${ parentPath.trim() }/`.replace(/\/+/g, '/');
  if (
    !parentTitle
    || !path
    || !parentPath
    || Object.keys(Locales)
      .some((locale) => path === ROUTES[locale as Locales].HOME)
  ) {
    return [{ label: title }];
  }

  return [
    { label: parentTitle, url: parentPath },
    { label: title },
  ];
};
