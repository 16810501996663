import React, { ElementType } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'src/views/misc/localization';
import SmartLinkTheme, { SmartLinkThemeType } from './theme';
import { isExternal, localizeLink, isNewTabLink } from '../../utils/link-utils';

export type SmartLinkType = {
  href: string,
  disabled?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler;
  rel?: string;
  theme?: SmartLinkThemeType;
  disableLocalization?: boolean;
  dataTest?: string;
}

const renderAs = (
  url: string,
  external: boolean,
  disabled: boolean,
): ElementType => {
  if (disabled || url === null || url === '') {
    return 'span';
  }

  if (external) {
    return 'a';
  }

  return Link;
};

const SmartLink: React.FC<SmartLinkType> = ({
  href,
  disabled = false,
  children,
  className,
  onClick,
  rel,
  dataTest = 'smart-link',
  disableLocalization = false,
  theme = SmartLinkTheme,
  ...rest
}) => {
  const { locale } = useTranslation();

  const isUrlExternal = isExternal(href);

  const internalUrl = href ? href.toLowerCase() : href;
  const url = isUrlExternal || disableLocalization
    ? href
    : localizeLink({ locale, link: internalUrl });

  return (
    <theme.Main
      as={renderAs(url, isUrlExternal, disabled)}
      to={url}
      href={url}
      target={isNewTabLink(url) ? '_blank' : ''}
      className={className}
      onClick={onClick}
      rel={rel}
      external={isUrlExternal ? 1 : 0}
      data-test={dataTest}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </theme.Main>
  );
};

SmartLink.displayName = 'SmartLink';

export default SmartLink;
