import { black, white } from '@shipae/components-sandbox/component/colors';
import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import GatsbyImageWithIEPolyfill from 'gatsby-image/withIEPolyfill';
import React from 'react';
import { media } from 'src/views/theme';
import styled from 'styled-components';

const Background = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  background-color: ${ black(0.7) };
`;
const Modal = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${ media.sm } {
    width: 90%;
  }

  background-color: ${ white() };
  border-radius: 1.2rem;
  padding: 4rem 3.2rem;
  overflow: hidden;
`;

type GatsbyImgProps = {
  fluid: FluidObject | FluidObject[];
};

const DesktopImg = styled(GatsbyImageWithIEPolyfill)<GatsbyImgProps>`
  width: 55rem;
  ${ media.sm } {
    display: none;
  }
`;

const MobileImg = styled(GatsbyImageWithIEPolyfill)<GatsbyImgProps>`
  width: 35rem;
  display: none;
  ${ media.sm } {
    display: block;
  }
`;

const DummyImg = styled(GatsbyImageWithIEPolyfill)<GatsbyImgProps>`
  display: none;
`;

type QueryResult = {
  desktopNoJs?: {
    childImageSharp: {
      fluid: FluidObject | FluidObject[];
    }
  }
  mobileNoJs?: {
    childImageSharp: {
      fluid: FluidObject | FluidObject[];
    }
  }
}

const NoJavascript: React.FC = () => {
  const data = useStaticQuery<QueryResult>(graphql`
    query {
      desktopNoJs: file(
        relativePath: {eq: "no-js-desktop.png"}
      ) {
        childImageSharp {
          fluid(quality: 65, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobileNoJs: file(
        relativePath: {eq: "no-js-mobile.png"}
      ) {
        childImageSharp {
          fluid(quality: 65, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <noscript>
      <Background />
      <Modal>
        <DesktopImg
          fluid={data.desktopNoJs?.childImageSharp?.fluid || []}
          alt="No javascript enabled"
          objectFit="contain"
          objectPosition="center center"
        />
        {/*
        On client route update, Gatsby somehow found MobileImg's classname,
        and changed its innerHtml to "Navigated to {pageName}".
        This is a Gatsby accessibility feature, but it messed up to work
        with noscript tag. Hence invisible DummyImg was created as a workaround
        to hide the "Navigated to" text.
        Caveat: DummyImg must be between DesktopImg and MobileImg
        */}
        <DummyImg
          fluid={data.mobileNoJs?.childImageSharp?.fluid || []}
          data-workaround="noscript behaviour is weird"
          alt="a fix for noscript messing up HTML"
          objectFit="contain"
          objectPosition="center center"
        />
        <MobileImg
          fluid={data.mobileNoJs?.childImageSharp?.fluid || []}
          alt="No javascript enabled"
          objectFit="contain"
          objectPosition="center center"
        />
      </Modal>
    </noscript>
  );
};

export default NoJavascript;
