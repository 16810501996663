import SmartLink from '../smart-link';

export const gloabalReactMdOptions = {
  forceBlock: true,
  overrides: {
    a: {
      component: SmartLink,
    },
  },
};

export default gloabalReactMdOptions;
